import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import { Link } from 'gatsby';
import { OutboundLink } from 'gatsby-plugin-gtag';
import { EuiSpacer } from '@elastic/eui';
import NavButtons from 'components/Indoor_Cameras/IN-6001_HD/NavButtons';
import CompareIndoor from 'components/Products/Compare/CompareIndoorFlyout';
import TableFeatures from 'components/Indoor_Cameras/IN-6001_HD/TableFeatures';
import IndoorData from 'components/Products/Flyouts/Graphs/IndoorGraphsFlyout';
import RadarGraphCard from 'components/Products/RadarGraphs/in6001-radargraph-grid';
import BreadCrumbs from 'components/Layout/BreadCrumbs';
import SEOHelmet from 'components/Layout/SEOHelmet';
export const _frontmatter = {
  "title": "IN-6001 HD",
  "path": "/Indoor_Cameras/IN-6001_HD/",
  "dateChanged": "2017-12-12",
  "author": "Mike Polinowski",
  "excerpt": "INSTAR IN-6001 HD Indoor IP Camera",
  "image": "../P_SearchThumb_IN-6001HD.png",
  "social": "/images/Search/P_SearchThumb_IN-6001HD.webp",
  "toc": "/images/Search/TOC_Icons/Wiki_Tiles_P-IN-6001HD_white.webp",
  "chapter": "Indoor Cameras"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">



    <SEOHelmet title='IN-6001 HD' dateChanged='2017-12-12' author='Mike Polinowski' tag='INSTAR IP Camera' description='INSTAR IN-6001 HD Indoor IP Camera' image='/images/Search/P_SearchThumb_IN-6001HD.png' twitter='/images/Search/P_SearchThumb_IN-6001HD.webp' location={props.location.pathname} mdxType="SEOHelmet" />
    <BreadCrumbs locationBC={props.location} locationEN={props.location.pathname} locationDE='/de/Innenkameras/IN-6001_HD/' locationFR='/fr/Indoor_Cameras/IN-6001_HD/' crumbLabel="IN-6001" mdxType="BreadCrumbs" />
    <NavButtons mdxType="NavButtons" />
    <h1 {...{
      "id": "in-6001-hd",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h1" {...{
        "href": "#in-6001-hd",
        "aria-label": "in 6001 hd permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`IN-6001 HD`}</h1>
    <CompareIndoor mdxType="CompareIndoor" />
    <h2 {...{
      "id": "product-overview",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h2" {...{
        "href": "#product-overview",
        "aria-label": "product overview permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Product Overview`}</h2>
    <p><span parentName="p" {...{
        "className": "gatsby-resp-image-wrapper",
        "style": {
          "position": "relative",
          "display": "block",
          "marginLeft": "auto",
          "marginRight": "auto",
          "maxWidth": "920px"
        }
      }}>{`
      `}<a parentName="span" {...{
          "className": "gatsby-resp-image-link",
          "href": "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/573d3/IN-6001_Overview_width1650.png",
          "style": {
            "display": "block"
          },
          "target": "_blank",
          "rel": "noopener"
        }}>{`
    `}<span parentName="a" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "50.43478260869565%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAKCAIAAAA7N+mxAAAACXBIWXMAAAsTAAALEwEAmpwYAAAB2klEQVQoz12RXU8TQRSG91+YmJiYIHKhhpDwC4xGjfoT0GBqmi4f1QYbaxpKikIbwYD3QrwA3C2NNxCodQGxSotu1WLQWhq4wo+wKLa2ZefsvmZ2zVo9mYszmXnmeWdGIMOsMgKwtr4+PBQZHRm6Gx3Iqq8BEBFjDMC3r18ejo/FJCk+HV/L5QAYJi+BkVGq8R2Jp8qRow1NTY2HDh6YmpgAwKwCsLVZbL/U5vcHotHh2YRiAmWd9hkJ3Kxzc+rFy+MnjrW0NDc2HJ6WpXq4uFEYHIzIscdz8wur6lsAVWYwMv7CK+nVixfOt7Y2nz19Kpl4Uh+78OljONQ79mBcluJZNctjGwYAweleZTK+bu+d/oFwb+iZsvAf3CF6XC6XR+xcXE5ZZtrXGTfXLHM6nbnmvT5y7/7tUN+SovwL54O3Av4er68nmFx8bsM6Y4JhmswwTeD7j73ZmZlu0Z2cn9vVNDsRET+3Vqumlpe6xKuPJid/lcsATE6Am3XiA8C797lzZ05m0ivWMv8OGwawUch3elyyNOWQf+5sT7Y2i33BwM0bvkh/+I2q1sfO5z+I7vauDveVy23xmMwR58HsIiJd13laImY1jrlSqXze3tZ2dnY1rVT66SC/AYG+7SNce6pYAAAAAElFTkSuQmCC')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<picture parentName="a">{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/e4706/IN-6001_Overview_width1650.avif 230w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/d1af7/IN-6001_Overview_width1650.avif 460w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/7f308/IN-6001_Overview_width1650.avif 920w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/e1c99/IN-6001_Overview_width1650.avif 1380w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/e02f7/IN-6001_Overview_width1650.avif 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/avif"
            }}></source><source parentName="picture" {...{
              "srcSet": ["/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/a0b58/IN-6001_Overview_width1650.webp 230w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/bc10c/IN-6001_Overview_width1650.webp 460w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/966d8/IN-6001_Overview_width1650.webp 920w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/445df/IN-6001_Overview_width1650.webp 1380w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/44758/IN-6001_Overview_width1650.webp 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/webp"
            }}></source>{`
          `}<source parentName="picture" {...{
              "srcSet": ["/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/81c8e/IN-6001_Overview_width1650.png 230w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/08a84/IN-6001_Overview_width1650.png 460w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/c0255/IN-6001_Overview_width1650.png 920w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/b1001/IN-6001_Overview_width1650.png 1380w", "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/573d3/IN-6001_Overview_width1650.png 1650w"],
              "sizes": "(max-width: 920px) 100vw, 920px",
              "type": "image/png"
            }}></source>{`
          `}<img parentName="picture" {...{
              "className": "gatsby-resp-image-image",
              "src": "/en/static/ae3be5d321fbbc5ce0cc827d5fe3560b/c0255/IN-6001_Overview_width1650.png",
              "alt": "INSTAR IN-6001 HD",
              "title": "INSTAR IN-6001 HD",
              "loading": "lazy",
              "decoding": "async",
              "style": {
                "width": "100%",
                "height": "100%",
                "margin": "0",
                "verticalAlign": "middle",
                "position": "absolute",
                "top": "0",
                "left": "0"
              }
            }}></img>{`
        `}</picture>{`
  `}</a>{`
    `}</span></p>
    <TableFeatures mdxType="TableFeatures" />
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The IN-6001 HD is the smallest INSTAR HD 720p camera for indoor surveillance inside your office or house. The camera can easily be controlled through your smartphone from inside your network and also over the internet. The camera can easily be connected to your network by using its 108MBit (802.11b/g/n) Wi-Fi module (WPA/WPA2) or through the 100MBit network interface to ensure a fluid video stream. The camera can also see during the night thanks to 8 Infrared LEDs operating at a wavelength of 850nm. The max. indoor range for the cameras night vision is around 5-10 meters. The spiritual successor of the IN-3001 will bring you all the features that you are used to from our VGA series, combined with a superior resolution of 1280x720 Pixel and the possibility to record videos on an integrated SD card. If an alarm is triggered the camera can send an email to notify you. Each email also has 4 pictures attached. This way it will be very easy to check quickly what caused the motion to make sure your home is safe.`}</p>
    <IndoorData mdxType="IndoorData" />
    <EuiSpacer mdxType="EuiSpacer" />
    <h3 {...{
      "id": "hardware",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#hardware",
        "aria-label": "hardware permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Hardware`}</h3>
    <ul>
      <li parentName="ul">{`Wide Dynamic Range (WDR) 720p `}<Link to="/Indoor_Cameras/IN-6001_HD/Technical_Specifications/" mdxType="Link">{`Omnivision CMOS Sensor`}</Link></li>
      <li parentName="ul">{`Automatic IRcut filter for natural colours`}</li>
      <li parentName="ul">{`2-Way audio via an integrated `}<Link to="/Web_User_Interface/1080p_Series/Multimedia/Audio/" mdxType="Link">{`Microphone & Speaker`}</Link></li>
      <li parentName="ul">{`4 Infrared LEDs (850nm) for nightvision`}</li>
    </ul>
    <h3 {...{
      "id": "network",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#network",
        "aria-label": "network permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Network`}</h3>
    <ul>
      <li parentName="ul">{`RJ-45 Ethernet port for an easy integration into your 100Mbit home network`}</li>
      <li parentName="ul">{`Compatible with 2.4 GHz `}<Link to="/Quick_Installation/Set_Up_A_Wireless_Connection/" mdxType="Link">{`2.4 GHz WiFi`}</Link>{` IEEE 802.11 b/g/n up to 54Mbit networks with WEP, WPA and WPA2 security`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Network/IP_Config/" mdxType="Link">DHCP</Link> and <Link to="/Web_User_Interface/720p_Series/Network/UPnP/" mdxType="Link">UPnP</Link> support for an automatic network integration
      </li>
      <li parentName="ul">{`Integrated personal, free DDNS ("Internet") Address and P2P User ID to access your camera through the Internet`}</li>
    </ul>
    <h3 {...{
      "id": "software",
      "style": {
        "position": "relative"
      }
    }}><a parentName="h3" {...{
        "href": "#software",
        "aria-label": "software permalink",
        "className": "header-link-icon before"
      }}><svg parentName="a" {...{
          "aria-hidden": "true",
          "focusable": "false",
          "height": "16",
          "version": "1.1",
          "viewBox": "0 0 16 16",
          "width": "16"
        }}><path parentName="svg" {...{
            "fillRule": "evenodd",
            "d": "M4 9h1v1H4c-1.5 0-3-1.69-3-3.5S2.55 3 4 3h4c1.45 0 3 1.69 3 3.5 0 1.41-.91 2.72-2 3.25V8.59c.58-.45 1-1.27 1-2.09C10 5.22 8.98 4 8 4H4c-.98 0-2 1.22-2 2.5S3 9 4 9zm9-3h-1v1h1c1 0 2 1.22 2 2.5S13.98 12 13 12H9c-.98 0-2-1.22-2-2.5 0-.83.42-1.64 1-2.09V6.25c-1.09.53-2 1.84-2 3.25C6 11.31 7.55 13 9 13h4c1.45 0 3-1.69 3-3.5S14.5 6 13 6z"
          }}></path></svg></a>{`Software`}</h3>
    <ul>
      <li parentName="ul">{`Free `}<Link to="/Software/Android/" mdxType="Link">{`Android`}</Link>{`, `}<Link to="/Software/iOS/" mdxType="Link">{`iPhone`}</Link>{` and `}<Link to="/Software/Windows/" mdxType="Link">{`Windows Phone`}</Link>{` App`}</li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/" mdxType="Link">Web user interface</Link> allows an easy configuration and camera control through your default web browser (Chrome, Safari, Firefox, Internet Explorer)
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/System/User/" mdxType="Link">Multi user management</Link> and simultaneous login for 3 users
      </li>
      <li parentName="ul">
        <Link to="/Web_User_Interface/720p_Series/Alarm/Areas/" mdxType="Link">Software-based motion</Link> and <Link to="/Web_User_Interface/720p_Series/Alarm/Actions/" mdxType="Link">audio</Link> detection and <Link to="/Motion_Detection/Alarm_Notification/" mdxType="Link"> email notification</Link> with attached snapshots
      </li>
      <li parentName="ul">{`Record videos directly to an `}<Link to="/Motion_Detection/SD_Card_Access/" mdxType="Link">{`Internal SD Card`}</Link></li>
    </ul>
    <EuiSpacer mdxType="EuiSpacer" />
    <p>{`The 1/3 inch WDR sensor is built on a 4.3 micron OmniPixel3-HS™ Pixel enabling 720p HD at unparalleled low-light sensitivity and a dynamic range in colour of 115 dB – compared to the 60 db of a regular VGA IP Camera. WDR allows you to capture high detailed colour videos even in high-contrast environmental lighting conditions.`}</p>
    <RadarGraphCard mdxType="RadarGraphCard" />

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      